import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  currentYear: number = new Date().getFullYear();

  constructor() {
    // This is intentional
  }

  ngOnInit() {
    // This is intentional
  }

  ngOnDestroy() {
    // This is intentional
  }
}
