import { Component, OnInit, Inject, computed, inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Papa } from 'ngx-papaparse';

import {
  CODEMIRROR_TYPE_MATCH,
  FILE_TYPE,
  ResponseType,
} from '../../constants/general.constants';
import { GeneralHelpers } from '../../helpers/general.helper';
import { Dialog } from '../../interfaces/create-dialog.interface';
import { FileItem, NotebookFile } from '../../interfaces/file.interface';
import { FileService } from '../../services/file/file.service';
import { CacheService } from '../../services/cache/cache.service';
import { SnapshotService } from '../../services/snapshot/snapshot.service';
import { ToastrService } from 'ngx-toastr';
import { tableFromIPC } from 'apache-arrow';
import { from, fromArrow } from 'arquero';
import { ConvertService } from '../../services/convert/convert.service';
import { PermissionId } from '../../interfaces/global-role.interface';
import { ProjectService } from '../../services/project/project.service';
import { NotebookDefaultFilesService } from '../../services/notebook-default-file/notebook-default-file.service';
import { AppStateService } from '../../services/app-state/app-state.service';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-watch-file-dialog',
  templateUrl: './watch-file-dialog.component.html',
  styleUrls: ['./watch-file-dialog.component.scss'],
})
export class WatchFileDialogComponent implements OnInit, OnDestroy {
  #projectService = inject(ProjectService);
  #appState = inject(AppStateService);

  hasWritePermission = computed(() =>
    this.#projectService.getCurrentUserHasProjectPermission(PermissionId.WRITE)
  );

  public fileItem!: FileItem | NotebookFile | any;
  public fileType!: string;
  public mode: string | any = null;
  public sizeFormatted!: string;
  public loading: boolean = false;
  public base64: any = '';
  public tableData!: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Dialog,
    private fileService: FileService,
    private cacheService: CacheService,
    private snapshotService: SnapshotService,
    private notebookDefaultFilesService: NotebookDefaultFilesService,
    private toastr: ToastrService,
    private papa: Papa,
    private convertService: ConvertService
  ) { }

  ngOnInit() {
    this.processFileData();
    this.setFileType();
    this.setMode();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
  }

  private processFileData() {
    this.fileItem = this.data?.dialogData;

    if (this.fileItem === undefined) {
      return;
    }

    this.sizeFormatted = GeneralHelpers.formatBytes(this.fileItem.filesize);

    const responseType = GeneralHelpers.getResponseType(this.fileItem.fileType);

    this.getById(responseType);
  }

  private getById(responseType?: string) {
    if (!!this.fileItem.snapshotId === true) {
      this.getSnapshotFile(responseType);
      return;
    }
    if (this.isDefaultFile(this.fileItem)) {
      this.getDefaultFile(responseType);
      return;
    }
    if (this.fileItem.cacheId !== undefined) {
      this.getNotebookFile(responseType);
      return;
    }
    this.getProjectFile(responseType);
  }

  private isDefaultFile(file: NotebookFile): boolean {
    return !!file.fileId && !file.cacheId && !file.snapshotId;
  }

  private async getDefaultFile(responseType?: string) {
    if (
      !this.fileItem.fileId ||
      !this.#appState.projectId() ||
      !this.#appState.notebookId()
    ) {
      this.toastr.error('Missing required file information');
      return;
    }

    this.notebookDefaultFilesService
      .getDefaultFileContents(
        this.#appState.projectId(),
        this.#appState.notebookId(),
        this.fileItem.fileId.toString(),
        responseType as any
      )
      .subscribe({
        next: (data: any) => {
          this.processFileCallback(data, responseType);
        },
        error: (error) => {
          this.toastr.error('Failed to load default file content');
          console.error('Error loading default file:', error);
        },
      });
  }

  private async getNotebookFile(responseType?: string) {
    this.cacheService
      .getCacheContentFile(`${this.fileItem.fileId}`, responseType as any)
      .subscribe({
        next: (data: any) => {
          this.processFileCallback(data, responseType);
        },
        error: () => {
          this.toastr.error('Failed to load cache file content');
        },
      });
  }

  private async getSnapshotFile(responseType?: string) {
    this.snapshotService
      .getSnapshotFile(
        this.fileItem.snapshotId,
        `${this.fileItem.fileId}`,
        responseType as any
      )
      .subscribe({
        next: (data: any) => {
          this.processFileCallback(data, responseType);
        },
        error: () => {
          this.toastr.error('Failed to load snapshot file content');
        },
      });
  }

  private processFileCallback(data: any, responseType?: string) {
    if (responseType === ResponseType.Blob) {
      this.processBlobData(data);
    } else if (
      this.fileItem.fileType === FILE_TYPE.csv &&
      responseType === ResponseType.Text
    ) {
      this.parseCsvFile(data);
    } else if (this.fileItem.fileType === FILE_TYPE.arrow) {
      this.processArrowData(data);
    } else if (this.fileItem.fileType === FILE_TYPE.parquet) {
      this.processParquetData(data);
    } else {
      this.data.model = data;
    }
  }

  private processParquetData(data: ArrayBuffer) {
    // convert arraybuffer to uint8array
    const uint8Data = new Uint8Array(data);
    this.convertService
      .parquetToArrowForWatchDialog(uint8Data)
      .then((arrowData) => {
        this.processArrowData(arrowData);
      });
  }

  private async processArrowData(data: any) {
    const tableData = data instanceof ArrayBuffer ? new Uint8Array(data) : data;
    let arrowTable: any;
    let arqueroTable: any;
    let tableJson: any;

    try {
      arrowTable = tableFromIPC(tableData);
      arqueroTable = fromArrow(arrowTable);
    } catch (error) {
      console.log('processArrowData arrowTable to arqueroTable: ', error);
    }

    try {
      tableJson = arqueroTable.toJSON();
    } catch (error) {
      console.log('processArrowData arqueroTable.toJSON(): ', tableJson);
    }

    if (!tableJson) {
      try {
        tableJson = this.papa.parse(arqueroTable.toCSV()).data;
      } catch (error) {
        console.log('processArrowData arqueroTable.toCSV(): ', tableJson);
        this.toastr.error('Error Arrow file is corrupted');
      }
    }

    if (GeneralHelpers.isJSON(tableJson)) {
      const tableData = GeneralHelpers.jsonParse(tableJson);
      this.tableData = GeneralHelpers.prepareTableFromArqueroJSON(tableData);
      return;
    }

    if (Array.isArray(tableJson) && tableJson.length > 0) {
      const arqueroTable = from(tableJson).toJSON();
      const tableData = GeneralHelpers.jsonParse(arqueroTable);
      this.tableData = GeneralHelpers.prepareTableFromArqueroJSON(tableData);
      return;
    }

    this.tableData = tableJson;
  }

  private getProjectFile(responseType?: string) {
    this.fileService
      .getById(this.fileItem.fsitemId, responseType as any)
      .subscribe({
        next: (data: any) => {
          this.processFileCallback(data, responseType);
        },
        error: () => { },
      });
  }

  private processBlobData(data: any) {
    GeneralHelpers.blobToBase64(data).then((base64Data: any) => {
      this.base64 = `${GeneralHelpers.getMimeType(
        this.fileItem.fileType,
        GeneralHelpers.fileExtensionFromString(this.fileItem.name)
      )}${base64Data}`;
    });
  }

  private setFileType() {
    const name = this.data?.dialogData?.name;
    if (name !== undefined) {
      this.fileType = GeneralHelpers.fileExtensionFromString(name);
    }
  }

  private setMode() {
    this.mode = (CODEMIRROR_TYPE_MATCH as any)[this.fileType];
  }

  public updateContent(data: any) {
    this.data.model = data;
  }

  public setFormTouched() { }

  private parseCsvFile(file: any) {
    const config = {
      header: true,
      delimiter: '', // auto-detect
      complete: (response: any) => {
        this.tableData = GeneralHelpers.addTableColumns(response.data);
      },
      error: (error: any) => {
        this.toastr.error(error.message);
      },
      download: false,
      skipEmptyLines: 'greedy',
      chunk: undefined,
      fastMode: undefined,
      withCredentials: undefined,
    };

    this.papa.parse(file, config as any);
  }
}
