import { Injectable } from '@angular/core';
import { ChunkContext } from '../../interfaces/chunk/chunk-context.interface';
import { Chunk } from '../../interfaces/chunk/chunk.interface';
import { ToastrService } from 'ngx-toastr';

interface ModuleRegistryEntry {
  id: string;
  name: string;
  code: string;
  namespace: any;
  isLoaded: boolean;
  lastUsed: number;
  useCount: number;
  dependencies: string[];
  exports: string[];
}

interface DependencyAnalysis {
  dependencies: string[];
  missingDependencies: string[];
}

@Injectable({
  providedIn: 'root',
})
export class PythonModuleManager {
  private moduleRegistry: Map<string, ModuleRegistryEntry> = new Map();
  private pythonRuntime: any;
  private jsExternalModulesNamespaces: any;
  private moduleCounter: number = 0;
  private isInitialized: boolean = false;

  constructor(private toastr: ToastrService) { }

  async initialize(runtime: any, jsExternalModulesNamespaces: any) {
    if (this.isInitialized) {
      return;
    }

    this.pythonRuntime = runtime;
    this.jsExternalModulesNamespaces = jsExternalModulesNamespaces;

    await this.setupModuleSystem();

    this.isInitialized = true;
  }

  private async setupModuleSystem() {
    const moduleSystemSetup = `
import sys
import io
import asyncio
import inspect
import traceback
from types import ModuleType
from contextlib import contextmanager

class UnifiedPythonSystem:
    """
    A unified system for managing Python module registration, execution, and output capture.
    Supports both synchronous and asynchronous code execution with proper module handling.
    """

    def __init__(self):
        """Initialize the Python system with necessary storage and output handling."""
        # Core storage for module management
        self._modules = {}        # Stores ModuleType instances
        self._module_code = {}    # Stores original source code for each module
        self._dependencies = {}   # Tracks inter-module dependencies
        self._exports = {}        # Tracks exported symbols for each module
        self._executed_modules = set()  # Tracks which modules have been fully executed

        # Output handling setup
        self._original_stdout = sys.stdout
        self._original_stderr = sys.stderr

    def setup_base_namespace(self, namespace=None):
        """
        Set up a base namespace with essential builtins and modules.
        Ensures all registered modules are available.
        """
        if namespace is None:
            namespace = {}

        # Add essential items to namespace
        namespace.update({
            '__builtins__': __builtins__,
            'asyncio': asyncio,
            'data': globals().get('data', {})
        })

        # Add all registered modules to namespace
        for module_id, module in self._modules.items():
            module_name = module.__name__.lower()
            namespace[module_name] = module
            # Also make module available under its original name
            if hasattr(module, '__name__'):
                namespace[module.__name__] = module

        # Add all non-private modules from sys.modules
        for module_name, module in sys.modules.items():
            if not module_name.startswith('_'):
                namespace[module_name] = module

        return namespace

    @contextmanager
    def _capture_output(self):
        """
        Context manager for capturing stdout and stderr output.
        Creates new StringIO for each capture to avoid output accumulation.
        """
        # Create new buffer for each capture
        output_buffer = io.StringIO()

        # Store original stdout/stderr
        original_stdout = sys.stdout
        original_stderr = sys.stderr

        # Redirect output to new buffer
        sys.stdout = output_buffer
        sys.stderr = output_buffer

        try:
            yield output_buffer
        finally:
            # Restore original stdout/stderr
            sys.stdout = original_stdout
            sys.stderr = original_stderr
            # Close buffer
            output_buffer.close()

    async def register_module(self, module_id: str, name: str, code: str):
        """
        Register or update a module without executing its code.
        Code execution is deferred until next import.
        """
        try:
            # Clean up existing module if present
            self._cleanup_module(module_id)

            # Setup module infrastructure
            namespace = self.setup_base_namespace()
            module = ModuleType(name)
            module.__file__ = f'<virtual_module_{module_id}>'
            module.__package__ = None
            module.__dict__.update(namespace)

            # Register in Python's module system
            sys.modules[module_id] = module

            # Store module information
            self._modules[module_id] = module
            self._module_code[module_id] = code

            return {
                'id': module_id,
                'exports': [],  # Will be populated on first import
                'namespace': module.__dict__,
                'is_loaded': True
            }

        except Exception as e:
            error_msg = f"Module registration failed: {str(e)}\\n{traceback.format_exc()}"
            print(error_msg)
            self._cleanup_module(module_id)
            raise RuntimeError(error_msg)

    async def execute_code(self, code: str, namespace=None):
        """
        Execute Python code with fresh output capture for each execution.
        Handles both synchronous and asynchronous code.
        """
        try:
            if namespace is None:
                namespace = {}
            namespace = self.setup_base_namespace(namespace)

            # Initialize result structure
            result = {
                'output': '',
                'namespace': namespace,
                'error': None
            }

            # Split code into imports and main code
            import_lines = []
            main_code_lines = []

            for line in code.split('\\n'):
                if line.strip().startswith(('import ', 'from ')):
                    import_lines.append(line)
                else:
                    main_code_lines.append(line)

            # Execute imports with fresh output capture
            if import_lines:
                import_code = '\\n'.join(import_lines)
                with self._capture_output() as buffer:
                    try:
                        exec(import_code, namespace)
                        result['output'] = buffer.getvalue() or 'Code executed successfully'
                    except Exception as e:
                        return {
                            'output': buffer.getvalue(),
                            'namespace': namespace,
                            'error': f"Import error: {str(e)}\\n{traceback.format_exc()}"
                        }

            # Execute main code with fresh output capture
            main_code = '\\n'.join(main_code_lines)
            is_async = 'async' in main_code or 'await' in main_code

            if is_async:
                indented_code = '\\n'.join('    ' + line for line in main_code.splitlines())
                func_name = f"__async_exec_{id(code)}"

                wrapped_code = (
                    f"async def {func_name}():\\n"
                    f"    globals().update(locals())\\n"
                    f"{indented_code}\\n"
                    f"    return locals()\\n\\n"
                    f"__exec_result = asyncio.ensure_future({func_name}())"
                )

                # Execute the wrapper function with fresh output capture
                with self._capture_output() as buffer:
                    try:
                        exec(wrapped_code, namespace)
                        # Get the future from namespace
                        future = namespace['__exec_result']
                        # Wait for completion
                        local_vars = await future
                        # Update namespace with new locals
                        namespace.update(local_vars)
                        result['output'] = buffer.getvalue() or 'Code executed successfully'
                        result['namespace'] = namespace
                    except Exception as e:
                        return {
                            'output': buffer.getvalue(),
                            'namespace': namespace,
                            'error': f"Async execution error: {str(e)}\\n{traceback.format_exc()}"
                        }
                    finally:
                        # Cleanup temporary names
                        namespace.pop('__exec_result', None)
                        namespace.pop(func_name, None)
            else:
                # Execute synchronous code with fresh output capture
                with self._capture_output() as buffer:
                    try:
                        exec(main_code, namespace)
                        result['output'] = buffer.getvalue() or 'Code executed successfully'
                        result['namespace'] = namespace
                    except Exception as e:
                        return {
                            'output': buffer.getvalue(),
                            'namespace': namespace,
                            'error': f"Execution error: {str(e)}\\n{traceback.format_exc()}"
                        }

            return result

        except Exception as e:
            # Create new buffer even for error reporting
            with self._capture_output() as buffer:
                print(f"General execution error: {str(e)}\\n{traceback.format_exc()}")
                return {
                    'output': buffer.getvalue(),
                    'namespace': namespace,
                    'error': f"General execution error: {str(e)}\\n{traceback.format_exc()}"
                }

    async def import_module(self, module_name: str, requesting_module: str = None):
        """
        Import a module and execute its code if not already executed.
        """
        try:
            module_name_lower = module_name.lower()

            print(f"\\nDiagnostics for {module_name_lower}:")
            print(f"sys.modules keys: {list(sys.modules.keys())}")
            print(f"_modules keys: {list(self._modules.keys())}")
            print(f"_module_code keys: {list(self._module_code.keys())}")
            print(f"_executed_modules: {self._executed_modules}")

            if module_name_lower in sys.modules:
                module = sys.modules[module_name_lower]

                # Execute code only if not executed before
                if module_name_lower not in self._executed_modules:
                    # Execute module code
                    result = await self.execute_code(
                        self._module_code[module_name_lower],
                        module.__dict__
                    )
                    if result.get('error'):
                        raise RuntimeError(f"Module execution failed: {result['error']}")

                    # Update module namespace
                    module.__dict__.update(result['namespace'])
                    for key, value in result['namespace'].items():
                        if not key.startswith('__'):
                            setattr(module, key, value)

                    # Update exports
                    exports = []
                    for key, value in module.__dict__.items():
                        if not key.startswith('_'):
                            if (inspect.isfunction(value) or
                                inspect.isclass(value) or
                                inspect.ismodule(value) or
                                not callable(value)):
                                exports.append(key)
                    self._exports[module_name_lower] = exports

                    # Mark as executed
                    self._executed_modules.add(module_name_lower)

                # Track dependencies
                if requesting_module and requesting_module in self._modules:
                    if requesting_module not in self._dependencies:
                        self._dependencies[requesting_module] = set()
                    self._dependencies[requesting_module].add(module_name_lower)

                return {
                    'module': module,
                    'exports': self._exports.get(module_name_lower, []),
                    'error': None
                }

            error_msg = f"Module '{module_name}' not found in system"
            print(error_msg)
            raise ImportError(error_msg)

        except Exception as e:
            error_msg = f"Import failed: {str(e)}\\n{traceback.format_exc()}"
            print(error_msg)
            return {
                'module': None,
                'exports': [],
                'error': error_msg
            }

    def _update_module_exports(self, module_name: str, namespace: dict):
        """
        Update the list of exports for a module based on its namespace.
        Identifies and tracks all valid exported symbols from the module.

        Args:
            module_name (str): Name of the module
            namespace (dict): Module's namespace
        """
        exports = []
        for attr_name, attr_value in namespace.items():
            if not attr_name.startswith('_'):
                if (inspect.isfunction(attr_value) or
                    inspect.isclass(attr_value) or
                    inspect.ismodule(attr_value) or
                    not callable(attr_value)):
                    exports.append(attr_name)
        self._exports[module_name] = exports

    def check_circular_imports(self, module_name: str, checking_path: set = None):
        """
        Check for circular dependencies starting from a given module.
        """
        if checking_path is None:
            checking_path = set()

        if module_name in checking_path:
            return True  # Circular dependency found

        checking_path.add(module_name)

        for dep in self._dependencies.get(module_name, set()):
            if self.check_circular_imports(dep, checking_path):
                return True

        checking_path.remove(module_name)
        return False

    def setup_base_namespace(self, namespace=None):
        """
        Set up a base namespace with essential builtins and modules.
        Ensures all registered modules are available.
        """
        if namespace is None:
            namespace = {}

        # Add essential items to namespace
        namespace.update({
            '__builtins__': __builtins__,
            'asyncio': asyncio,
            'data': globals().get('data', {})
        })

        # Add all registered modules to namespace
        for module_id, module in self._modules.items():
            if module_id in self._executed_modules:
                namespace[module_id] = module
                # Also add by the module's actual name
                if hasattr(module, '__name__'):
                    namespace[module.__name__] = module

        # Add all non-private modules from sys.modules
        for module_name, module in sys.modules.items():
            if not module_name.startswith('_'):
                namespace[module_name] = module

        return namespace

    def get_module_dependencies(self, module_name: str) -> list:
        """Get all dependencies for a module."""
        return list(self._dependencies.get(module_name, set()))

    def get_module_exports(self, module_id: str) -> list:
        """Get list of exports for a module."""
        return self._exports.get(module_id, [])

    def _cleanup_module(self, module_id: str):
        """
        Comprehensive cleanup of module registration and resources.
        """
        # Remove from sys.modules
        if module_id in sys.modules:
            del sys.modules[module_id]

        # Clean up internal storage
        if module_id in self._modules:
            del self._modules[module_id]
        if module_id in self._module_code:
            del self._module_code[module_id]
        if module_id in self._exports:
            del self._exports[module_id]
        if module_id in self._executed_modules:
            self._executed_modules.remove(module_id)
        if module_id in self._dependencies:
            del self._dependencies[module_id]

# Create global instance
module_system = UnifiedPythonSystem()
`;

    try {
      await this.pythonRuntime.runPythonAsync(moduleSystemSetup);
    } catch (error) {
      console.error('Failed to setup module system:', error);
      this.toastr.error('Failed to initialize Python system', 'Initialization Error');
      throw error;
    }
  }

  async registerModule(name: string, code: string, context?: ChunkContext): Promise<boolean> {
    const moduleId = this.sanitizeModuleName(name);

    try {
      const existingModule = this.moduleRegistry.get(moduleId);
      const registrationCode = `await module_system.register_module("${moduleId}", "${moduleId}", ${JSON.stringify(code)})`;
      const registration = await this.pythonRuntime.runPythonAsync(registrationCode);

      this.moduleRegistry.set(moduleId, {
        id: moduleId,
        name: name,
        code: code.trim(),
        namespace: registration.namespace,
        isLoaded: registration.is_loaded,
        lastUsed: Date.now(),
        useCount: existingModule ? existingModule.useCount : 0,
        dependencies: [],
        exports: registration.exports
      });

      return true;
    } catch (error) {
      const errorMessage = `Failed to register module ${name}: ${error}`;
      console.error(errorMessage);

      if (context) {
        context.addMessage(errorMessage, 'danger');
      }

      throw error;
    }
  }

  async importModule(moduleName: string, context?: ChunkContext, requestingModule?: string): Promise<any> {
    try {
      const moduleId = this.sanitizeModuleName(moduleName);
      const registryEntry = this.moduleRegistry.get(moduleId);

      if (!registryEntry) {
        throw new Error(`Module '${moduleName}' not found`);
      }

      console.log(`Importing module ${moduleName} (id: ${moduleId})`);
      console.log('Registry entry:', registryEntry);

      const importCode = `
        async def do_import():
            result = await module_system.import_module(
                "${moduleId}",
                ${requestingModule ? `"${requestingModule}"` : 'None'}
            )
            print(f"Import result: {result}")
            return result

        await do_import()
      `;

      const result = await this.pythonRuntime.runPythonAsync(importCode);

      if (result.error) {
        throw new Error(result.error);
      }

      // Update registry
      registryEntry.lastUsed = Date.now();
      registryEntry.useCount += 1;
      registryEntry.exports = result.exports;
      this.moduleRegistry.set(moduleId, registryEntry);

      return result.module;
    } catch (error) {
      const errorMessage = `Failed to import module ${moduleName}: ${error}`;
      console.error(errorMessage);
      if (context) {
        context.addMessage(errorMessage, 'danger');
      }
      throw error;
    }
  }
  async diagnoseModuleState(moduleName: string): Promise<void> {
    const sanitizedName = this.sanitizeModuleName(moduleName);
    const diagnosticCode = `
      async def diagnose_module(name):
          print(f"Diagnosing module: {name}")
          print(f"Is in sys.modules: {name in sys.modules}")
          if name in sys.modules:
              module = sys.modules[name]
              print(f"Module attributes: {dir(module)}")
              print(f"Is in _executed_modules: {name in module_system._executed_modules}")
              print(f"Registered exports: {module_system._exports.get(name, [])}")
          else:
              print("Module not found in sys.modules")

      await diagnose_module("${sanitizedName}")
  `;

    try {
      await this.pythonRuntime.runPythonAsync(diagnosticCode);
    } catch (error) {
      console.error(`Diagnostic failed for module ${moduleName}:`, error);
    }
  }

  private generateModuleId(name: string): string {
    const sanitizedName = this.sanitizeModuleName(name);
    // TODO: think about current implementation for module ID generation
    return `${sanitizedName}_${this.moduleCounter++}`;
    // return `${sanitizedName}_1`;
  }

  private sanitizeModuleName(name: string): string {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9_]/g, '_')
      .replace(/^[^a-z]/, 'mod_$&');
  }

  /**
   * Public method to analyze dependencies in Python code
   */
  analyzeDependencies(code: string): DependencyAnalysis {
    const dependencies = this.extractDependencies(code);
    const missingDependencies = dependencies.filter(
      dep => !this.moduleRegistry.has(dep)
    );

    return {
      dependencies,
      missingDependencies
    };
  }

  private async validateDependencies(
    dependencies: string[],
    context?: ChunkContext
  ): Promise<boolean> {
    for (const dep of dependencies) {
      if (!this.moduleRegistry.has(dep)) {
        const error = `Missing dependency: ${dep}`;
        if (context) {
          context.addMessage(error, 'danger');
        }
        throw new Error(error);
      }
    }
    return true;
  }

  private extractDependencies(code: string): string[] {
    const dependencies = new Set<string>();
    const importRegex = /^(?:\s*import\s+(\S+)(?:\s+as\s+\S+)?|\s*from\s+(\S+)\s+import\s+\S+(?:\s+as\s+\S+)?)/gm;

    let match;
    while ((match = importRegex.exec(code)) !== null) {
      const moduleName = (match[1] || match[2]).split('.')[0];

      if (this.moduleRegistry.has(moduleName)) {
        dependencies.add(moduleName);
      }
    }

    return Array.from(dependencies);
  }

  async updateModule(
    moduleId: string,
    newCode: string,
    context: ChunkContext
  ): Promise<boolean> {
    try {
      const entry = this.moduleRegistry.get(moduleId);
      if (!entry) {
        throw new Error(`Module ${moduleId} not found`);
      }

      // Unload existing module
      await this.pythonRuntime.runPythonAsync(
        `module_manager.unload_module("${moduleId}")`
      );

      // Register updated module
      console.log('updateModule: ', entry.name);
      const success = await this.registerModule(entry.name, newCode, context);

      if (!success) {
        // Rollback to old version on failure
        await this.registerModule(entry.name, entry.code, context);
        throw new Error('Module update failed, rolled back to previous version');
      }

      return success;

    } catch (error) {
      const errorMessage = `Failed to update module ${moduleId}: ${error}`;
      console.error(errorMessage);
      if (context) {
        context.addMessage(errorMessage, 'danger');
      }
      throw error;
    }
  }

  getModuleInfo(moduleId: string): ModuleRegistryEntry | undefined {
    return this.moduleRegistry.get(moduleId);
  }

  listModules(): Array<{ id: string, name: string, exports: string[] }> {
    return Array.from(this.moduleRegistry.values())
      .map(({ id, name, exports }) => ({ id, name, exports }));
  }

  async validateModuleDependencies(dependencies: string[], context?: ChunkContext): Promise<boolean> {
    try {
      // Check all dependencies exist
      for (const dep of dependencies) {
        const moduleId = this.sanitizeModuleName(dep);
        if (!this.moduleRegistry.has(moduleId)) {
          throw new Error(`Missing dependency: ${dep}`);
        }
      }

      // Check for circular dependencies
      for (const dep of dependencies) {
        const moduleId = this.sanitizeModuleName(dep);
        const checkCode = `module_system.check_circular_imports("${moduleId}")`;
        const hasCircular = await this.pythonRuntime.runPythonAsync(checkCode);
        if (hasCircular) {
          throw new Error(`Circular dependency detected involving module: ${dep}`);
        }
      }

      return true;
    } catch (error) {
      const errorMessage = `Dependency validation failed: ${error}`;
      if (context) {
        context.addMessage(errorMessage, 'danger');
      }
      throw error;
    }
  }
}
