import { Component, OnInit, Inject, inject, HostBinding, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as marked from 'marked';
import _ from 'lodash';
import { MessageService } from '../../services/message/message.service';
import { ChunkType } from '../../logic/chunk-type';
import { Chunk } from '../../interfaces/chunk/chunk.interface';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-chunk-item-description',
  templateUrl: './chunk-item-description.component.html',
  styleUrls: ['./chunk-item-description.component.scss']
})
export class ChunkItemInfoComponent implements OnInit, OnDestroy {
  chunkItemInfoForm!: FormGroup;
  chunkType!: ChunkType;
  chunkData: Partial<Chunk> = {};
  @HostBinding('class.fullscreen') isFullscreen: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChunkItemInfoComponent>,
    private _formBuilder: FormBuilder,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    const { dialogData } = this.data;

    this.chunkData = dialogData.chunkData;

    this.initForm();
  }

  ngOnDestroy() {
    // intentionally empty
  }

  private initForm() {
    this.chunkItemInfoForm = this._formBuilder.group({
      description: [this.chunkData.description ?? ''],
    });
  }

  private resetForm() {
    this.chunkItemInfoForm.reset();

    (Object as any)
      .values(this.chunkItemInfoForm.controls)
      .forEach((control: FormControl) => {
        control.setErrors(null);
        control.markAsUntouched();
        control.markAsPristine();
      });
  }

  public submitChunkInfoForm(value: any, isValid: boolean, $event: any) {
    $event.preventDefault();

    if (!isValid) {
      return;
    }

    const updatedChunk = {
      ...this.chunkData,
      description: value.description,
    };

    this.resetForm();
    this.dialogRef.close(updatedChunk);
  }

  public setFormTouched() {
    this.chunkItemInfoForm.markAsTouched();
  }

  public updateContent(data: string) {
    this.chunkItemInfoForm.get('description')?.setValue(data);

    for (const key in this.chunkItemInfoForm.controls) {
      if (Object.prototype.hasOwnProperty.call(this.chunkItemInfoForm.controls, key)) {
        this.chunkItemInfoForm.controls[key].markAsDirty();
      }
    }
  }

  toggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;
  }
}
