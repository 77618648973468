import { Component, OnInit, OnDestroy, Inject, Optional } from '@angular/core';
import { fromEvent, map, merge, of, Subscription } from 'rxjs';

import {
  SIDE_NAV_ACTIVE_COMPONENT_BLOCKS,
  SIDE_NAV_ACTIVE_COMPONENT_FILES,
  SIDE_NAV_LEFT_POSITION,
} from './shared/constants/general.constants';
import { MessageService } from './shared/services/message/message.service';
import { AutoUnsubscribe } from './shared/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  networkStatus: boolean = true;
  networkStatus$: Subscription = Subscription.EMPTY;

  // Side nav
  isOpened: boolean = false;
  position: 'left' | 'right' = 'left';
  activeComponent: 'blocks' | 'files' = 'blocks';

  // Message subscription
  private messageSubscription!: Subscription;
  private authSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private messageService: MessageService,
    @Optional() @Inject('ZITADEL_CONFIG') private zitadelConfig: any
  ) {
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (message && message.text === 'close_side_nav') {
          this.close();
        }
        if (message && message.text === 'open_side_nav') {
          const position = message?.data?.position
            ? message?.data?.position
            : SIDE_NAV_LEFT_POSITION;
          this.open(position);
        }
      });
  }

  ngOnInit() {
    this.checkNetworkStatus();
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.networkStatus = status;
      });
  }

  close() {
    this.isOpened = false;
  }

  open(position: 'left' | 'right') {
    this.isOpened = true;
    this.position = position;
    this.activeComponent =
      position === 'left'
        ? SIDE_NAV_ACTIVE_COMPONENT_BLOCKS
        : SIDE_NAV_ACTIVE_COMPONENT_FILES;
  }

  clickOutsideHandler() {
    this.close();
  }
}
