import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  EMPTY,
  Observable,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
  filter,
  of,
} from 'rxjs';
import { Project } from '../../../shared/interfaces/project.interface';
import { ProjectService } from '../../../shared/services/project/project.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router, NavigationEnd } from '@angular/router';

import { GeneralHelpers } from '../../helpers/general.helper';
import { ArrowAlias } from '../../interfaces/arrow-alias.interface';
import { AppStateService } from '../../services/app-state/app-state.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
})
export class HeaderSearchComponent implements OnInit, OnDestroy {
  #appState = inject(AppStateService);
  projectCtrl: FormControl;
  filteredProjects: Observable<any[]>;
  toHighlight: string = '';
  keyed!: boolean;
  projects: Project[] | any = [];
  noDataFound: boolean = false;

  constructor(
    private projectService: ProjectService,
    private router: Router,
    private authService: AuthenticationService
  ) {
    router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.projectCtrl.setValue('');
      });

    this.projectCtrl = new FormControl();

    this.filteredProjects = this.projectCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((projectName: string) => {
        return this.filterProjects(projectName);
      })
    );
  }

  ngOnInit() { }

  ngOnDestroy() { }

  public optionSelected(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value;
    switch (value.type) {
      case 'project':
        this.goToProject(value);
        break;
      default:
    }
  }

  private filterProjects(val: string): Observable<any[]> {
    if (typeof val !== 'string') {
      return EMPTY;
    }
    if (!this.authService.isAuthenticated()) {
      return of([]);
    }
    this.toHighlight = val;
    val = GeneralHelpers.removeSpaces(val);
    this.noDataFound = false;
    return this.projectService.get().pipe(
      map((response: Project[] | any) => {
        response = this.processProjects(response);
        return response.filter((option: Project | any) => {
          if (!!option.name === false || val === '') {
            return;
          }
          return option.name.toLowerCase().indexOf(val.toLowerCase()) === 0;
        });
      }),
      tap((items: any[]) => {
        if (items.length === 0) {
          this.noDataFound = true;
        }
      })
    );
  }

  private processProjects(projects: Project[]) {
    const flattenedProjects = this.flattenProjects(projects);
    const filteredProjects = flattenedProjects.filter(
      (item: any) => item.name !== 'files'
    );
    return filteredProjects;
  }

  private flattenProjects(projects: Project[]) {
    const flattenData = projects.flatMap((item) => {
      const hasRequiredProperties =
        'name' in item && 'projectId' in item && 'userId' in item;

      if (!hasRequiredProperties) {
        throw new Error('Item is missing required properties');
      }

      const projectData = {
        name: item.name,
        projectId: item.projectId,
        userId: item.userId,
        description: (item as any).description,
        type: 'project',
      };

      const aliasData = item.aliases
        ? item.aliases.map((alias: ArrowAlias) => ({
          ...alias,
          type: 'alias',
        }))
        : [];
      return [projectData, ...aliasData];
    });

    return flattenData;
  }

  public displayItem(item: any) {
    if (item === null) {
      return '';
    }
    if (typeof item === 'object') {
      return `${item.type}: ${item.name}`;
    }
    return item.name;
  }

  displayUser(user: any) { }

  public goToProject(project: Project) {
    this.#appState.navigateToProject(Number(project.projectId));
  }
}
