import { Component, Input, OnInit, ViewChild, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { CodemirrorComponent } from '../../modules/codemirror/codemirror.component';
// ─────────────────────────────────────────────────────────────────────────────
// Toolbars
import { MARKDOWN_ACTIONS } from './toolbars/markdown.toolbar';
import { JAVASCRIPT_ACTIONS } from './toolbars/javascript.toolbar';
import { PYTHON_ACTIONS } from './toolbars/python.toolbar';
import { SQL_ACTIONS } from './toolbars/sql.toolbar';
// ─────────────────────────────────────────────────────────────────────────────
// Formatter
import { js_beautify } from 'js-beautify'
// ─────────────────────────────────────────────────────────────────────────────
// Darkmode support
import { DarkModeService } from '../../services/dark-mode/dark-mode.service';
import { DialogService } from '../../services/dialog/dialog.service';
import { Dialog } from '@angular/cdk/dialog';
import { PythonService } from '../../services/python/python.service';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss']
})
export class CodeEditorComponent implements OnInit, AfterViewInit, OnDestroy {

  showToolbar: boolean = false;

  toolbarContent: any[] = [];

  options: any = {
    autoRefresh: true,
    firstLineNumber: 1,
    lineNumbers: true,
    lineWrapping: true,
    smartIndent: true,
    indentWithTabs: true,
    foldGutter: true,
    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
    styleActiveLine: { nonEmpty: true },
    matchBrackets: true,
    autoCloseBrackets: true,
    findMatchingBrackets: true,
    refresh: true,
    viewportMargin: Infinity,
    lint: { esversion: 6 },
    keyMap: 'sublime',
  }

  @Input() mode!: string;
  @Input() content!: string;

  @Output() updateContent: EventEmitter<any> = new EventEmitter();
  @Output() startEdit: EventEmitter<any> = new EventEmitter();

  @ViewChild(CodemirrorComponent, { static: false }) codeMirror!: any;

  private darkMode$ = this.darkModeService.darkMode$;

  constructor(
    private darkModeService: DarkModeService,
    private dialogService: DialogService,
    private pythonService: PythonService,
    private toastrService: ToastrService,
  ) {
    // This is intentional
    this.darkMode$.subscribe((data: any) => {
      let theme = data ? 'monokai' : 'eclipse';
      this.options.theme = theme;
    })
  }

  ngOnInit() {
    this.getDefaultOptions()
    this.setToolbarVisibility();
  }

  ngAfterViewInit() {
    // This is intentional
  }

  ngOnDestroy(): void {
    // This method is intentionally empty
  }

  getDefaultOptions() {
    this.options.mode = this.mode;
  }

  handleChange(data: string) {
    this.updateContent.emit(data);
  }

  touchEditor() {
    this.startEdit.emit();
  }

  // ─────────────────────────────────────────────────────────────────────
  // Markdown toolbar

  private setToolbarVisibility() {
    switch (this.mode) {
      case 'markdown':
        this.showToolbar = true;
        this.toolbarContent = MARKDOWN_ACTIONS;
        break;

      case 'javascript':
        this.showToolbar = true;
        this.toolbarContent = JAVASCRIPT_ACTIONS;
        break;

      case 'python':
        this.showToolbar = true;
        this.toolbarContent = PYTHON_ACTIONS;
        break;

      case 'sql':
        this.showToolbar = true;
        this.toolbarContent = SQL_ACTIONS;
        break;

      default:
        break;
    }
  }

  public processToolbarCommand(name: string) {
    let selectedText = this.codeMirror?.codeMirror?.getSelection();
    let initText: string = '';
    switch (name) {
      case 'bold':
        initText = 'Bold Text';
        selectedText = `**${selectedText || initText}**`;
        this.codeMirror?.codeMirror?.replaceSelection(selectedText);
        break;
      case 'italic':
        initText = 'Italic Text';
        selectedText = `*${selectedText || initText}*`;
        this.codeMirror?.codeMirror?.replaceSelection(selectedText);
        break;
      case 'heading':
        initText = 'Heading';
        selectedText = `# ${selectedText || initText}`;
        this.codeMirror?.codeMirror?.replaceSelection(selectedText);
        break;
      case 'reference':
        initText = 'Reference';
        selectedText = `> ${selectedText || initText}`;
        this.codeMirror?.codeMirror?.replaceSelection(selectedText);
        break;
      case 'link':
        selectedText = `[](http://)`;
        this.codeMirror?.codeMirror?.replaceSelection(selectedText);
        break;
      case 'image':
        selectedText = `![](http://)`;
        this.codeMirror?.codeMirror?.replaceSelection(selectedText);
        break;
      case 'ul':
        selectedText = `- ${selectedText || initText}`;
        this.codeMirror?.codeMirror?.replaceSelection(selectedText);
        break;
      case 'ol':
        selectedText = `1. ${selectedText || initText}`;
        this.codeMirror?.codeMirror?.replaceSelection(selectedText);
        break;
      case 'code':
        initText = 'Source Code';
        selectedText = "```language\r\n" + (selectedText || initText) + "\r\n```";
        this.codeMirror?.codeMirror?.replaceSelection(selectedText);
        break;

      case 'clear-code':
        this.content = '';
        break;

      case 'js-beautify':
        this.content = js_beautify(this.content);
        break;

      case 'py-beautify':
        this.pythonService.formatCode(this.content)
          .then((code: string) => {
            this.content = code;
          })
          .catch((error: any) => {
            console.log('Error Python code autoformatting: ', error);
            this.toastrService.warning('Python code could not be autoformatted.');
          });

        break;
      case 'open_duckdb_control_dialog':
        const data = {
          title: 'DuckDB Controls',
          confirmCaption: '',
          cancelCaption: 'Cancel',
          hasClose: true,
          dialogData: {
          }
        } as Dialog | any
        this.dialogService.databaseControlsDialog(data)
        break;


      default:
        break;
    }

  }

}
