import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
// Dialog support
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog } from '../../interfaces/create-dialog.interface';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-edit-project-dialog',
  templateUrl: './edit-project-dialog.component.html',
  styleUrls: ['./edit-project-dialog.component.scss']
})
export class EditProjectDialogComponent implements OnInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Dialog,) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    // This method is intentionally empty
  }
}
