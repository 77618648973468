import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { MessageService } from '../../services/message/message.service';
import { ProjectService } from '../../services/project/project.service';
import { ToastrService } from 'ngx-toastr';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Notebook, Project } from '../../interfaces/project.interface';
import { GeneralHelpers } from '../../helpers/general.helper';
import { Subscription } from 'rxjs';
import { DialogService } from '../../services/dialog/dialog.service';
import {
  PROJECT_FORM_UPDATE,
  RELOAD_NOTEBOOKS,
  RELOAD_FILES,
  RELOAD_CHUNKS,
  RELOAD_JS_RUNTIME,
  RELOAD_WASM,
} from '../../constants/general.constants';
import { AppStateService } from '../../services/app-state/app-state.service';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
})
export class ProjectFormComponent implements OnInit, OnDestroy {
  #appState = inject(AppStateService);

  @Input() workMode: 'create' | 'update' = 'create';

  @Input() project: Project | any;

  @Input() formAlign: 'row' | 'column' = 'row';

  public projectForm!: FormGroup;

  public messageSubscription!: Subscription;

  constructor(
    private messageService: MessageService,
    private projectService: ProjectService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,

    private dialogService: DialogService
  ) {
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (message && message.text === PROJECT_FORM_UPDATE) {
          this.updateForm(this.projectService.getSelectedProject());
        }
      });
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe
  }

  ngOnInit() {
    this.initForm();
    if (this.workMode === 'update') {
      const project = this.project
        ? this.project
        : this.projectService.getSelectedProject();
      this.updateForm(project);
      this.removeValidationAndHideField(['notebookName']);
    }
  }

  // ─────────────────────────────────────────────────────────────────────
  // Project form

  private initForm() {
    this.projectForm = this._formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      notebookName: ['', [Validators.required]],
    });
  }

  public processProjectForm(formValue: any, isValid: boolean, $event: Event) {
    $event.preventDefault();
    if (!isValid) {
      return;
    }

    if (this.workMode === 'create') {
      this.projectService
        .create({
          name: formValue.name,
          description: formValue.description,
          notebookName: formValue.notebookName,
        })
        .subscribe({
          next: (data: Notebook | any) => {
            this.projectCallback(data);
          },
          error: () => { },
        });
    } else if (this.workMode === 'update') {
      const project = this.project
        ? this.project
        : this.projectService.getSelectedProject();
      this.projectService
        .update(
          {
            name: formValue.name,
            description: formValue.description,
          },
          project.projectId
        )
        .subscribe({
          next: (data: Notebook | any) => {
            this.projectCallback(data);
          },
          error: () => { },
        });
    }
  }

  private updateForm(data: Notebook) {
    this.projectForm.get('name')?.setValue(data.name);
    this.projectForm.get('notebookName')?.setValue(null);
  }

  private projectCallback(data?: Notebook) {
    if (data) {
      this.#appState.navigateToProject(Number(data.projectId));
    }

    this.messageService.sendMessage(RELOAD_NOTEBOOKS);
    this.messageService.sendMessage(RELOAD_FILES);
    this.messageService.sendMessage(RELOAD_CHUNKS);
    //this.messageService.sendMessage(RELOAD_GLOBAL_VAR);
    this.messageService.sendMessage(RELOAD_JS_RUNTIME);
    this.messageService.sendMessage(RELOAD_WASM);

    this.toastr.success(
      'Success',
      `Project ${this.workMode === 'create' ? 'created' : 'updated'}`
    );

    GeneralHelpers.resetForm(this, 'projectForm');
  }

  public cancel() {
    this.resetForm();
  }

  private resetForm() {
    this.projectForm.reset();
    (Object as any)
      .values(this.projectForm.controls)
      .forEach((control: FormControl) => {
        control.setErrors(null);
        control.markAsUntouched();
        control.markAsPristine();
      });
  }

  public openDeleteConfirmationPopup(
    id = this.#appState.projectId().toString()
  ) {
    this.dialogService
      .confirmDialog({
        title: 'Are you sure ?',
        message: 'After you delete project you will lost data forever',
        confirmCaption: 'Confirm',
        cancelCaption: 'Cancel',
        dialogData: {
          id,
        },
      })
      .subscribe((data) => {
        if (data.dialogData && data.dialogData.id) {
          this.projectService
            .delete(`${this.#appState.projectId()}`)
            .subscribe({
              next: () => {
                this.toastr.success('Project was deleted');

                this.projectCallback();
              },
              error: () => {
                this.toastr.error('Project was not deleted');
              },
            });
        }
      });
  }

  private removeValidationAndHideField(fields: string[]): void {
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index];
      let currentFieldsAccessor: any = this.projectForm.get(element);
      currentFieldsAccessor.setValidators(null);
      currentFieldsAccessor.updateValueAndValidity();
    }
  }
}
