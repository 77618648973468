import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Editor,
  EditorChange,
  EditorFromTextArea,
  ScrollInfo,
} from 'codemirror';
import {
  CodeMirrorEditor,
  Hint,
  CodeMirrorPosition,
  CodeMirrorToken,
} from './codemirror.interface';
import { CodemirrorAutocomplete } from '../../constants/additional-methods.constants';
import { debounceTime, filter, fromEvent, map } from 'rxjs';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

function normalizeLineEndings(str: string): string {
  if (!str) {
    return str;
  }
  return str.replace(/\r\n|\r/g, '\n');
}

declare const CodeMirror: any;

@AutoUnsubscribe()
@Component({
  selector: 'ngx-codemirror',
  template: `
    <textarea
      [name]="name"
      class="ngx-codemirror {{ className }}"
      [class.ngx-codemirror--focused]="isFocused"
      autocomplete="off"
      [autofocus]="autoFocus"
      #ref
    >
    </textarea>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodemirrorComponent),
      multi: true,
    },
  ],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodemirrorComponent
  implements AfterViewInit, OnDestroy, ControlValueAccessor, DoCheck {
  @Input() className: string = '';
  @Input() name: string = 'codemirror';
  @Input() autoFocus: boolean = false;

  @Input()
  set options(value: { [key: string]: any }) {
    this._options = value;
    if (!this._differ && value) {
      this._differ = this._differs.find(value).create();
    }
  }

  @Input() preserveScrollPosition = false;
  @Output() cursorActivity = new EventEmitter<Editor>();
  @Output() focusChange = new EventEmitter<boolean>();
  @Output() scroll = new EventEmitter<ScrollInfo>();
  @Output() drop = new EventEmitter<[Editor, DragEvent]>();
  @Output() codeMirrorLoaded = new EventEmitter<CodemirrorComponent>();
  @ViewChild('ref') ref!: ElementRef<HTMLTextAreaElement>;

  value = '';
  disabled = false;
  isFocused = false;
  private codeMirror?: EditorFromTextArea;
  private readonly MINIMUM_HEIGHT = 300;
  private _codeMirror: any;
  private _differ?: KeyValueDiffer<string, any>;
  private _options: any;

  constructor(private _differs: KeyValueDiffers, private _ngZone: NgZone) { }

  get codeMirrorGlobal(): any {
    if (this._codeMirror) {
      return this._codeMirror;
    }

    this._codeMirror =
      typeof CodeMirror !== 'undefined' ? CodeMirror : import('codemirror');
    return this._codeMirror;
  }

  ngAfterViewInit() {
    this._ngZone.runOutsideAngular(async () => {
      const codeMirrorObj = await this.codeMirrorGlobal;
      const codeMirror = codeMirrorObj?.default
        ? codeMirrorObj.default
        : codeMirrorObj;
      this.codeMirror = codeMirror.fromTextArea(
        this.ref.nativeElement,
        Object.assign(this._options, {
          viewportMargin: Infinity,
          indentWithTabs: false, // Use spaces instead of tabs
          indentUnit: 2, // Number of spaces per indentation level
          tabSize: 2, // Number of spaces that a tab represents
          extraKeys: {
            'Ctrl-Space': 'autocomplete',
            'Cmd-Space': 'autocomplete',
            'Tab': (cm: any) => {
              if (cm.somethingSelected()) {
                cm.indentSelection('add');
              } else {
                cm.replaceSelection(cm.getOption('indentWithTabs')
                  ? '\t'
                  : ' '.repeat(cm.getOption('indentUnit')), 'end', '+input');
              }
            },
            'Shift-Tab': (cm: any) => {
              cm.indentSelection('subtract');
            },
            F11: function (cm: any) {
              cm.setOption('fullScreen', !cm.getOption('fullScreen'));
            },
            'Cmd-Enter': function (cm: any) {
              cm.setOption('fullScreen', !cm.getOption('fullScreen'));
            },
            Esc: function (cm: any) {
              if (cm.getOption('fullScreen')) cm.setOption('fullScreen', false);
            },
            'Ctrl-/': function (cm: any) {
              cm.toggleComment({ fullLines: false });
            },
            'Cmd-/': function (cm: any) {
              cm.toggleComment({ fullLines: false });
            },
          },
          hint: this.globalApiHint,
          hintOptions: {
            hint: this.globalApiHint,
          },
        })
      ) as EditorFromTextArea;
      this.setupAutocomplete();
      this.codeMirror.on('cursorActivity', (cm: any) =>
        this._ngZone.run(() => this.cursorActive(cm))
      );
      this.codeMirror.on('scroll', this.scrollChanged.bind(this));
      this.codeMirror.on('blur', () =>
        this._ngZone.run(() => this.focusChanged(false))
      );
      this.codeMirror.on('focus', () =>
        this._ngZone.run(() => this.focusChanged(true))
      );
      this.codeMirror.on('change', (cm: Editor, change: EditorChange) => {
        this._ngZone.run(() => {
          this.codemirrorValueChanged(cm, change);
          this.updateEditorHeight();
        });
      });
      this.codeMirror.on('drop', (cm: any, e: any) => {
        this._ngZone.run(() => this.dropFiles(cm, e));
      });
      this.codeMirror.setValue(this.value);
    });
  }

  private updateEditorHeight() {
    if (this.codeMirror) {
      const wrapper = this.codeMirror.getWrapperElement();
      const lineCount = this.codeMirror.lineCount();
      const lineHeight = this.codeMirror.defaultTextHeight();
      const contentHeight = lineCount * lineHeight + 10; // Add some padding
      const height = Math.max(contentHeight, this.MINIMUM_HEIGHT);
      wrapper.style.height = `${height}px`;
      this.codeMirror.refresh();
    }
  }

  private setupAutocomplete() {
    fromEvent(this.codeMirror as any, 'keyup')
      .pipe(
        map((event: KeyboardEvent | any) => event),
        filter((event: KeyboardEvent) => !event.ctrlKey && !event.metaKey),
        debounceTime(300),
        map(() => {
          const cursor = (this.codeMirror as any).getCursor();
          const token = (this.codeMirror as any).getTokenAt(cursor);
          return token.string.trim();
        }),
        filter((currentWord: string) => currentWord.length >= 3)
      )
      .subscribe(() => {
        (this.codeMirror as any).execCommand('autocomplete');
      });
  }

  ngDoCheck() {
    if (!this._differ) {
      return;
    }
    const changes = this._differ.diff(this._options);
    if (changes) {
      changes.forEachChangedItem((option) =>
        this.setOptionIfChanged(option.key, option.currentValue)
      );
      changes.forEachAddedItem((option) =>
        this.setOptionIfChanged(option.key, option.currentValue)
      );
      changes.forEachRemovedItem((option) =>
        this.setOptionIfChanged(option.key, option.currentValue)
      );
    }
  }

  ngOnDestroy() {
    if (this.codeMirror) {
      this.codeMirror.toTextArea();
    }
  }

  globalApiHint(editor: CodeMirrorEditor): Hint {
    const cursor: CodeMirrorPosition = editor.getCursor();
    const token: CodeMirrorToken = editor.getTokenAt(cursor);
    const start: number = token.start;
    const end: number = cursor.ch;
    const currentWord: string = token.string;
    const list: string[] = CodemirrorAutocomplete;
    const filteredList: string[] = list.filter((item) =>
      item.startsWith(currentWord)
    );
    return {
      list: filteredList,
      from: { line: cursor.line, ch: start },
      to: { line: cursor.line, ch: end },
    };
  }

  codemirrorValueChanged(cm: Editor, change: EditorChange) {
    const cmVal = cm.getValue();
    const normalizedValue = normalizeLineEndings(cmVal);
    if (this.value !== normalizedValue) {
      this.value = normalizedValue;
      this.onChange(this.value);
    }
  }

  setOptionIfChanged(optionName: string, newValue: any) {
    if (!this.codeMirror) {
      return;
    }
    this.codeMirror.setOption(optionName as any, newValue);
  }

  focusChanged(focused: boolean) {
    this.onTouched();
    this.isFocused = focused;
    this.focusChange.emit(focused);
  }

  scrollChanged(cm: Editor) {
    this.scroll.emit(cm.getScrollInfo());
  }

  cursorActive(cm: Editor) {
    this.cursorActivity.emit(cm);
  }

  dropFiles(cm: Editor, e: DragEvent) {
    this.drop.emit([cm, e]);
  }

  writeValue(value: string) {
    if (value === null || value === undefined) {
      return;
    }
    const normalizedValue = normalizeLineEndings(value);
    if (!this.codeMirror) {
      this.value = normalizedValue;
      return;
    }
    const cur = this.codeMirror.getValue();
    if (
      normalizedValue !== cur &&
      normalizeLineEndings(cur) !== normalizedValue
    ) {
      this.value = normalizedValue;
      this.codeMirror.setValue(this.value);
      this.updateEditorHeight();
    }
  }

  registerOnChange(fn: (value: string) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.setOptionIfChanged('readOnly', this.disabled);
  }

  private onChange = (_: any) => { };
  private onTouched = () => { };
}
