import { Component, OnDestroy, OnInit, computed, inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { ProjectService } from '../../shared/services/project/project.service';
import { NotificationWebsocketService } from '../../shared/services/notification-websocket/notification-websocket.service';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { LocalstorageHelper } from 'src/app/shared/helpers/localstorage.helper';
import { AuthenticationService } from '../../shared/services/authentication/authentication.service';
import {
  BaseRequest,
  CurrentEditor,
  Entry,
  MarkSeenRequest,
} from '../../shared/interfaces/notification.interface';
import {
  HEADER_TITLE,
  NotificationWebsocketMessages,
  TOGGLE_LEFT_PANEL,
  TOGGLE_RIGHT_PANEL,
} from '../../shared/constants/general.constants';
import { AutoUnsubscribe } from '../../shared/decorators/auto-unsubscribe.decorator';
import { MessageService } from 'src/app/shared/services/message/message.service';
import { Project } from 'src/app/shared/interfaces/project.interface';

interface UserProfile {
  name: string;
  picture: string | null; // Changed to explicitly handle null
  email?: string;
  given_name?: string;
  family_name?: string;
  sub?: string;
}

@AutoUnsubscribe()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  #appState = inject(AppStateService);

  public userProfile: UserProfile | null = null;
  public userNotifications: Entry[] = [];
  public isMainVersion = computed(
    () => this.#appState.isHeadVersion() ?? false
  );
  public currentVersion = computed(() => this.#appState.versionId() ?? 0);
  public project = computed(() => this.#appState.project());
  public isCurrentEditor: boolean = true;
  public projectName = computed(
    () => this.#appState.project()?.name ?? HEADER_TITLE
  );
  public notebookName = computed(() => this.#appState.notebook()?.name ?? '');
  public currentUserName = computed(
    () => this.#appState.user()?.name ?? 'unknown'
  );
  public currentEditor: CurrentEditor = {
    projectId: 0,
    userId: null,
    username: null,
    nickname: null,
  };
  public projectLocked: boolean = false;
  public projectLockedBy: string | null = null;
  private notificationSubscription!: Subscription;
  private routerSubscription!: Subscription;
  public showLockLabel: boolean = false;
  public showLockButton: boolean = false;
  public showUnlockButton: boolean = false;
  public showOwnerUnlockLabel: boolean = false;
  public userRole: string | undefined;
  private isOwner: boolean = false;

  public leftPanelVisible: boolean = true;
  public rightPanelVisible: boolean = true;

  public user = this.#appState.user;
  public recentProjects: Project[] = [];

  constructor(
    private router: Router,
    private projectService: ProjectService,
    private notificationWebsocketService: NotificationWebsocketService,
    private toastr: ToastrService,
    public authService: AuthenticationService,
    private messageService: MessageService
  ) {
    this.setupRouterSubscription();
    this.setupNotificationSubscription();
    this.initializePanelVisibility();
  }

  private setupRouterSubscription(): void {
    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        if (event.url === '/projects') {
          this.#appState.clearProject();
          this.setRoleLabel();
        } else if (event.url?.includes('/project')) {
          this.refreshNames();
          this.checkProjectLocked();
          this.setRoleLabel();
        }
      });
  }

  private setupNotificationSubscription(): void {
    this.notificationSubscription = this.notificationWebsocketService
      .receiveMessage()
      .subscribe((data: BaseRequest) => {
        if (
          data.message === NotificationWebsocketMessages.NEW_ENTRIES &&
          data.entries
        ) {
          this.userNotifications = data?.entries.filter(
            (notification: Entry) => !notification.entrySeen
          );
        }
        if (
          data.message === NotificationWebsocketMessages.CURRENT_EDITOR &&
          data.editor
        ) {
          this.handleCurrentEditorMessage(data.editor);
        }
      });

    this.sendInitialMessagesForWebsocket();
  }

  private initializePanelVisibility(): void {
    this.leftPanelVisible = LocalstorageHelper.getLeftPanelVisible();
    this.rightPanelVisible = LocalstorageHelper.getRightPanelVisible();
  }

  ngOnInit() {
    this.loadUserData();
    this.loadRecentProjects();
  }

  ngOnDestroy(): void {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private loadUserData() {
    if (environment.authProvider === 'zitadel') {
      this.authService.getZitadelUser().subscribe({
        next: (user) => {
          if (user) {
            this.userProfile = user;
            this.#appState.loadUser();
          }
        },
        error: (error) => {
          console.error('Error fetching Zitadel user:', error);
        },
      });
    }
  }

  private handleCurrentEditorMessage(editor: CurrentEditor): void {
    this.#appState.currentEditor.set(editor);
    this.currentEditor = editor;
    this.isCurrentEditor = this.#appState.isCurrentEditor();
    this.isOwner = this.#appState.isProjectOwner();

    if (this.project() && this.project()?.projectId === editor.projectId) {
      this.projectLocked = editor.userId !== null && !this.isCurrentEditor && !this.isOwner;
      this.projectLockedBy = this.projectLocked ? editor.username : null;

      if (this.projectLocked) {
        this.toastr.info(
          `Project is locked by ${this.projectLockedBy}`,
          'Project Locked'
        );
        LocalstorageHelper.setProjectLockFlag(true);
      } else if (this.isCurrentEditor) {
        this.toastr.success(
          'You have locked the project for editing',
          'Project Locked'
        );
        LocalstorageHelper.setProjectLockFlag(false);
      }

      if (!this.projectLocked) {
        LocalstorageHelper.removeProjectLockFlag();
      }

      this.setLockControlsState();
      this.setRoleLabel();
    }
  }

  public hasNotifications(): boolean {
    return this.userNotifications.length > 0;
  }

  private refreshNames(): void {
    this.projectName = computed(
      () => this.#appState.project()?.name ?? HEADER_TITLE
    );
    this.notebookName = computed(() => this.#appState.notebook()?.name ?? '');
  }

  private setLockControlsState() {
    this.showOwnerUnlockLabel = false;
    this.showLockLabel = this.projectLocked && !this.isOwner;
    this.showLockButton = !this.projectLocked && this.isOwner && this.currentEditor.username === null;
    this.showUnlockButton = this.isCurrentEditor || (this.isOwner && this.currentEditor.username !== null);

    if (this.isOwner && this.showUnlockButton) {
      this.showOwnerUnlockLabel = true;
    }
  }

  public logout(): void {
    LocalstorageHelper.clear();
    this.authService.logout();
  }

  public onNotificationOpened() {
    if (this.hasNotifications()) {
      const unseenNotifications = this.userNotifications.filter(
        (notification) => !notification.entrySeen
      );

      if (unseenNotifications.length > 0) {
        const message: MarkSeenRequest = {
          message: 'mark-seen',
          entry_ids: unseenNotifications.map(
            (notification) => notification.entryId
          ),
        };
        this.notificationWebsocketService.sendMessage(message);
      }
    }
  }

  private checkProjectLocked(projectId = this.#appState.projectId()): void {
    if (!projectId) {
      return;
    }

    this.notificationWebsocketService
      .getCurrentEditor(projectId)
      .then(() => { })
      .catch(() => { });
  }

  public doProjectLock(showToast = true): void {
    this.notificationWebsocketService
      .lockEditor(this.#appState.projectId())
      .then(() => {
        this.toastr.success('Project locked');
      })
      .catch(() => {
        this.toastr.error('Error locking project');
      });
  }

  public doProjectUnlock(showToast = true): void {
    if (!this.#appState.isCurrentEditor() && !this.isOwner) {
      return;
    }

    this.notificationWebsocketService
      .unlockEditor(this.#appState.projectId())
      .then(() => {
        this.toastr.success('Project unlocked');
      })
      .catch(() => {
        this.toastr.error('Error unlocking project');
      });
  }

  private sendInitialMessagesForWebsocket(): void {
    this.notificationWebsocketService.watchEditors();
    this.notificationWebsocketService.getSubscriptions();
  }

  private setRoleLabel(): void {
    if (this.project()) {
      this.userRole = this.projectService.getCurrentUserRoleFromProject(
        this.project()
      );
    } else {
      this.userRole = undefined;
    }
  }

  private loadRecentProjects() {
    this.recentProjects = LocalstorageHelper.getRecentProjects();
  }

  public openRecentProject(project: Project) {
    this.#appState.navigateToProject(project.projectId as number);
  }

  public toggleLeftPanel(): void {
    this.leftPanelVisible = !this.leftPanelVisible;
    LocalstorageHelper.setLeftPanelVisible(this.leftPanelVisible);
    this.messageService.sendMessage(TOGGLE_LEFT_PANEL, this.leftPanelVisible);
  }

  public toggleRightPanel(): void {
    this.rightPanelVisible = !this.rightPanelVisible;
    LocalstorageHelper.setRightPanelVisible(this.rightPanelVisible);
    this.messageService.sendMessage(TOGGLE_RIGHT_PANEL, this.rightPanelVisible);
  }

  public onMenuOpened() {
    this.loadRecentProjects();
  }
}
