import { inject, Injectable } from '@angular/core';
import {
  PROJECT,
  API_BASE,
  VERSION,
  USERS,
  INVITES,
  OPERATOR_ROLE_ID,
} from '../../constants/general.constants';
import { catchError, EMPTY, map, Observable, Subject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  Project,
  ProjectCreate,
  Version,
  VersionCreate,
  VersionUpdate,
} from '../../interfaces/project.interface';
import { User } from '../../interfaces/user.interface';
import { PermissionsService } from '../permissions/permissions.service';
import { PermissionId } from '../../interfaces/global-role.interface';
import { AppStateService } from '../app-state/app-state.service';
import { LocalstorageHelper } from '../../helpers/localstorage.helper';

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100 MB

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  #permissionsService = inject(PermissionsService);
  #appState = inject(AppStateService);

  public $setProjectListSubject = new Subject<Project[]>();
  public projectList: Project[] = [];

  constructor(private http: HttpClient) {}

  // Helper method to construct project-related URLs
  private createProjectUrl(
    projectId?: string | number,
    resource?: string,
    subResource?: string
  ): string {
    let url = `${API_BASE}${PROJECT}`;
    if (projectId !== undefined) {
      url += `/${projectId}`;
    }
    if (resource) {
      url += `/${resource}`;
    }
    if (subResource) {
      url += `/${subResource}`;
    }
    return url;
  }

  // Helper method to handle FormData creation for project import
  private createFormData(file: File, jsonData: any): FormData {
    const formData = new FormData();

    // Convert JSON object to a string
    const jsonString = JSON.stringify(jsonData);
    const jsonBlob = new Blob([jsonString], { type: 'application/json' });

    // Create a File object from the Blob
    const jsonFile = new File([jsonBlob], 'import-data.json', {
      type: 'application/json',
    });

    // Append the JSON file and project zip file
    formData.append('json', jsonFile);
    formData.append('file', file);

    return formData;
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create Notebook

  /**
   * Create a new project.
   * @param data - The data required to create a new project.
   * @returns An Observable containing the created project.
   */
  create(data: ProjectCreate): Observable<Project> {
    return this.http.post<Project>(this.createProjectUrl(), data);
  }

  /**
   * Fetch all projects.
   * @returns An Observable containing the list of projects.
   */
  get(): Observable<Project> {
    return this.http.get<Project>(this.createProjectUrl()).pipe(
      map((res: any) => {
        if (res === null) return;
        this.$setProjectListSubject.next(res);
        return res;
      })
    );
  }

  /**
   * Fetch project by its ID.
   * @param projectId - The ID of the project.
   * @returns An Observable containing the project data.
   */
  getById(projectId: string): Observable<Project> {
    return this.http.get<Project>(this.createProjectUrl(projectId));
  }

  /**
   * Update a project by its ID.
   * @param data - The data to be updated.
   * @param projectId - The ID of the project.
   * @returns An Observable containing the updated project data.
   */
  public update(
    data: { name: string; description: string },
    projectId: string
  ): Observable<Project> {
    return this.http.put<Project>(this.createProjectUrl(projectId), data);
  }

  /**
   * Delete a project by its ID.
   * @param projectId - The ID of the project.
   * @returns An Observable after project deletion.
   */
  public delete(projectId: string | null): Observable<Object> {
    if (projectId === null) {
      return EMPTY;
    }
    return this.http.delete<Project>(this.createProjectUrl(projectId));
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Project Versions

  createProjectVersion(
    projectId: number,
    version: VersionCreate
  ): Observable<any> {
    return this.http.post(this.createProjectUrl(projectId, VERSION), version);
  }

  updateProjectVersion(
    projectId: number,
    versionId: number,
    version: VersionUpdate
  ): Observable<any> {
    return this.http.put(
      this.createProjectUrl(projectId, VERSION, `${versionId}`),
      version
    );
  }

  // Remove a user from a project
  removeUserFromProjectTeam(
    projectId: number,
    userId: number
  ): Observable<any> {
    return this.http.delete(
      this.createProjectUrl(projectId, USERS, `${userId}`)
    );
  }

  //--------------------------------------------------------------------------------
  // Project Invites

  // Fetch all invites for a specific project
  getInvitesForProject(projectId: number): Observable<any> {
    return this.http.get(this.createProjectUrl(projectId, INVITES));
  }

  // Invite a user to a project
  inviteUserToProject(
    projectId: number,
    inviteData: { inviteeId: number; roleId: number }
  ): Observable<any> {
    return this.http.post(
      this.createProjectUrl(projectId, INVITES),
      inviteData
    );
  }

  // Get details of a specific invite for a project
  getInviteForProject(projectId: number, inviteId: number): Observable<any> {
    return this.http.get(
      this.createProjectUrl(projectId, INVITES, `${inviteId}`)
    );
  }

  // Cancel an invite for a project
  cancelInviteForProject(projectId: number, inviteId: number): Observable<any> {
    return this.http.put(
      this.createProjectUrl(projectId, INVITES, `${inviteId}`),
      {}
    );
  }

  /**
   * Gets the full information for a project version including files and notebooks
   * @param projectId The id of the project
   * @param versionId The id of the version
   * @returns The full version object
   */
  getFullVersion(
    projectId: number | undefined,
    versionId: number | undefined
  ): Observable<Version> {
    if (projectId === undefined || versionId === undefined) {
      return EMPTY;
    }
    return this.http.get<Version>(
      this.createProjectUrl(projectId, VERSION, `${versionId}`)
    );
  }

  //--------------------------------------------------------------------------------
  // Fill version with notebooks and files based on version

  getProjectNotebookAndFiles(
    projectId: string,
    versionId: string | null = `${this.#appState.versionId()}`
  ): Observable<any> {
    if (!versionId) {
      return EMPTY;
    }
    return this.http.get(this.createProjectUrl(projectId, VERSION, versionId));
  }

  // ─────────────────────────────────────────────────────────────────────
  // Get selected project

  public getSelectedProject(): Project | any {
    const projectId = this.#appState.projectId();
    const projectList = this.#appState.projects();
    let selectedProject: Project | any = {};
    if (!!projectId !== false && projectList.length > 0) {
      for (const element of projectList) {
        if (projectId == element.projectId) {
          selectedProject = element;
          break;
        }
      }
    }
    if (!!projectId !== false && projectList.length > 0) {
      selectedProject = projectList[0];
      this.#appState.navigateToProject(selectedProject.projectId);
    }
    return selectedProject;
  }

  /**
   * Filter projects by name.
   * @param filterValue - The search term.
   * @returns An Observable containing the filtered list of projects.
   */
  public getFiltered(filterValue: string): Observable<Project[]> {
    return this.get().pipe(
      map((projects: any) =>
        projects.filter((project: Project) =>
          project.name?.includes(filterValue)
        )
      )
    );
  }

  public getCurrentUserRoleFromProject(
    project: Project | undefined = this.#appState.project()
  ) {
    if (!project?.users?.length) return;
    const userId = this.#appState.user()?.userId ?? 0;
    const projectUser = project.users.find((u) => u.userId === userId);

    return projectUser ? projectUser.roleName : '';
  }

  /**
   * Filters an array of projects to remove any HEAD versions that the user does not have permission to edit,
   * then removes any projects with no versions left
   * @param projects The array of projects to filter
   * @param user The user to check permissions for
   * @returns A filtered array that excludes projects the user does not have permission to view
   */
  public filterProjectsThatCanBeOpened(
    projects: Project[],
    user: User
  ): Project[] {
    return projects
      .map((project) => {
        if (
          this.getUserHasPermissionForProject(
            project,
            user,
            PermissionId.WRITE
          ) ||
          this.getUserHasPermissionForProject(project, user, PermissionId.READ)
        ) {
          return {
            ...project,
            selectedVersion: this.getHeadVersion(project),
          };
        }
        return project;
      })
      .filter((project) => project.versions && project.versions.length > 0);
  }

  /**
   * Gets the role of the user in the project
   * @param project The project to get the user role from
   * @param user The user to get the role for
   * @param permission The permission to check
   * @returns The role of the user in the project
   */
  public getUserHasPermissionForProject(
    project: Project,
    user: User,
    permission: PermissionId
  ): boolean {
    const projectUser = project.users?.find((u) => u.userId === user.userId);

    if (!projectUser) {
      return false;
    }

    const roleId =
      LocalstorageHelper.getProjectLockFlag() === true ||
      !this.#appState.isHeadVersion()
        ? OPERATOR_ROLE_ID
        : projectUser.roleId;

    const hasPermission = this.#permissionsService.roleHasPermission(
      this.#permissionsService.roleWithId(roleId),
      permission
    );
    return hasPermission;
  }

  /**
   * Checks to see if the current user has a specific permission for the current project
   * @param permission The desired permission
   * @returns true if the user has the permission, false otherwise
   */
  public getCurrentUserHasProjectPermission(permission: PermissionId) {
    const project = this.#appState.project();
    const user = this.#appState.user();
    if (!project || !user) {
      return false;
    }
    const hasPermission = this.getUserHasPermissionForProject(
      project,
      user,
      permission
    );
    return hasPermission;
  }

  /**
   * Gets the head version of the project
   * @param project The project to get the head version from
   * @returns The head version of the project
   */
  public getHeadVersion(project: Project): Version | undefined {
    return (
      project.versions?.find(
        (version) => version.majorVersion === 0 && version.minorVersion === 0
      ) || project.versions?.[0]
    );
  }

  /**
   * Export a project by its ID.
   * @param projectId - The ID of the project to export.
   * @returns An Observable for the export operation.
   */
  exportProject(projectId: string): Observable<Blob> {
    return this.http.get(this.createProjectUrl(projectId, 'export'), {
      responseType: 'blob',
    });
  }

  /**
   * Import a project or version from an exported file.
   * @param file - The exported project file (zip).
   * @param projectName - The name of the project extracted from the zip file.
   * @param versionId - The version ID.
   * @param aliasSecrets - Object containing alias secrets (optional).
   * @returns An Observable containing the imported project data and any warnings.
   */
  importProject(
    file: File,
    projectName: string,
    versionId: number,
    aliasSecrets?: { [key: string]: string }
  ): Observable<any> {
    if (file.size > MAX_FILE_SIZE) {
      return throwError(() => new Error('File size exceeds maximum allowed'));
    }

    if (!file.name.endsWith('.zip')) {
      return throwError(
        () => new Error('Invalid file type. Only zip files are allowed')
      );
    }

    const formData = this.createFormData(file, {
      projectName: projectName,
      versionId: versionId,
      aliasSecrets: aliasSecrets || {},
    });

    return this.http.post<any>(`${API_BASE}import`, formData).pipe(
      catchError((error) => {
        console.error('Import failed:', error);
        return throwError(() => new Error('Import failed. Please try again.'));
      })
    );
  }

  /**
   * Export a specific version of a project.
   * @param projectId - The ID of the project.
   * @param versionId - The ID of the version to export.
   * @returns An Observable for the export operation.
   */
  exportVersion(projectId: string, versionId: string): Observable<Blob> {
    return this.http.get(
      this.createProjectUrl(projectId, 'v', `${versionId}/export`),
      { responseType: 'blob' }
    );
  }

  /**
   * Check if a project name is unique.
   * @param projectName - The project name to check.
   * @returns A boolean indicating if the name is unique.
   */
  isProjectNameUnique(projectName: string): boolean {
    // First check the AppState
    const projects = this.#appState.projects();
    return !projects.some((project: Project) => project.name === projectName);
  }

  automatedImportProject(
    blob: Blob,
    projectName: string,
    versionId: number
  ): Observable<any> {
    const file = new File([blob], 'project_export.zip', {
      type: 'application/zip',
    });

    if (file.size > MAX_FILE_SIZE) {
      return throwError(() => new Error('File size exceeds maximum allowed'));
    }

    const formData = this.createFormData(file, {
      projectName: projectName,
      versionId: versionId,
      aliasSecrets: {},
    });

    return this.http.post<any>(`${API_BASE}import`, formData).pipe(
      catchError((error) => {
        console.error('Automated import failed:', error);
        return throwError(
          () => new Error('Automated import failed. Please try again.')
        );
      })
    );
  }

  /**
   * Add a feature to a project
   * @param projectId - The ID of the project
   * @param featureId - The ID of the feature to add
   * @returns An Observable containing the updated project
   */
  addProjectFeature(projectId: number, featureId: number): Observable<Project> {
    return this.http.post<Project>(
      this.createProjectUrl(projectId, 'feature', `${featureId}`),
      {}
    );
  }

  /**
   * Remove a feature from a project
   * @param projectId - The ID of the project
   * @param featureId - The ID of the feature to remove
   * @returns An Observable containing the updated project
   */
  removeProjectFeature(
    projectId: number,
    featureId: number
  ): Observable<Project> {
    return this.http.delete<Project>(
      this.createProjectUrl(projectId, 'feature', `${featureId}`)
    );
  }
}
