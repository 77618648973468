import { Component, OnInit, Inject, inject, NgZone, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog } from '../../interfaces/create-dialog.interface';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { ProjectService } from '../../services/project/project.service';
import { ToastrService } from 'ngx-toastr';
import { Version } from '../../interfaces/project.interface';
import { MessageService } from '../../services/message/message.service';
import { RELOAD_VERSIONS } from '../../constants/general.constants';
import { LocalstorageHelper } from '../../helpers/localstorage.helper';
import { AppStateService } from '../../services/app-state/app-state.service';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-version-dialog',
  templateUrl: './version-dialog.component.html',
  styleUrls: ['./version-dialog.component.scss'],
})
export class VersionDialogComponent implements OnInit, OnDestroy {
  #appState = inject(AppStateService);
  #ngZone = inject(NgZone);

  public versionForm!: FormGroup;

  public pattern: string = '0{10}';

  private readonly MAX_VERSION_NUMBER: number = 2147483647;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Dialog,
    private _formBuilder: FormBuilder,
    private projectService: ProjectService,
    private toastr: ToastrService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() { }

  private initForm() {
    this.versionForm = this._formBuilder.group(
      {
        majorVersion: [
          '',
          [
            Validators.required,
            Validators.max(this.MAX_VERSION_NUMBER),
            Validators.min(0), // Ensure majorVersion is greater than 0
          ],
        ],
        minorVersion: [
          '',
          [
            Validators.required,
            Validators.max(this.MAX_VERSION_NUMBER),
            Validators.min(0),
          ],
        ],
      },
      {
        validator: this.validateVersion(),
      }
    );
  }

  // Custom validator to ensure majorVersion > 0 when minorVersion is 0
  private validateVersion() {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const majorVersion = formGroup.get('majorVersion')?.value;
      const minorVersion = formGroup.get('minorVersion')?.value;

      if (Number(majorVersion) === 0 && Number(minorVersion) === 0) {
        formGroup.get('minorVersion')?.setErrors({ min: true });
        return { min: true };
      }

      return null;
    };
  }

  public processVersionForm(formValue: any, isValid: boolean, $event: Event) {
    $event.preventDefault();

    if (!isValid) {
      return;
    }

    const versionData: any = {
      majorVersion: Number(formValue.majorVersion),
      minorVersion: Number(formValue.minorVersion),
    };
    const versions = this.#appState.project()?.versions;

    if (versions?.find(({ majorVersion, minorVersion }) =>
      versionData.majorVersion === majorVersion
      && versionData.minorVersion === minorVersion
    )) {
      this.toastr.error(
        `Version ${versionData.majorVersion}.${versionData.minorVersion} already exists`,
        'Error'
      );
      return;
    }

    const projectId = this.#appState.projectId();

    if (!projectId) {
      this.toastr.error('No project selected', 'Error');
      return;
    }

    this.projectService
      .createProjectVersion(Number(projectId), versionData)
      .subscribe({
        next: (res: Version) => {
          this.resetForm();
          this.toastr.success('Version created successfully', 'Success');

          this.messageService.sendMessage(
            RELOAD_VERSIONS,
            versionData,
          );
        },
        error: (err: any) => {
          this.toastr.error(err.error.message, 'Error');
        },
      });
  }

  private resetForm() {
    this.versionForm.reset();
    (Object as any)
      .values(this.versionForm.controls)
      .forEach((control: FormControl) => {
        control.setErrors(null);
        control.markAsUntouched();
        control.markAsPristine();
      });
  }
}
