import { Component, OnInit, Inject, inject, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog } from '../../interfaces/create-dialog.interface';
import { ChunkTypeService } from '../../services/chunk-type/chunk-type.service';
import { CreateChunk } from '../../interfaces/chunk/chunk.interface';
import { ChunkService } from '../../services/chunk/chunk.service';
import { AppStateService } from '../../services/app-state/app-state.service';
import {
  Notebook,
  NotebookUpdate,
} from '../../interfaces/project.interface';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-chunk-dialog',
  templateUrl: './chunk-dialog.component.html',
  styleUrls: ['./chunk-dialog.component.scss'],
})
export class ChunkDialogComponent implements OnInit, OnDestroy {
  #appState = inject(AppStateService);

  chunkForm: FormGroup;
  chunkTypeList: any[] = [];
  selectedChunkType: any;
  showNotebookInput = false;
  mode: 'create' | 'edit' = 'create';
  notebook?: Notebook;

  constructor(
    private fb: FormBuilder,
    private chunkTypeService: ChunkTypeService,
    private chunkService: ChunkService,
    public dialogRef: MatDialogRef<ChunkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Dialog
  ) {
    this.mode = data.mode || 'create';
    this.notebook = data.notebook;
    this.showNotebookInput =
      data.showNotebookInput === undefined ? false : data.showNotebookInput;

    this.chunkForm = this.fb.group({
      name: ['', [Validators.required, this.uniqueNameValidator.bind(this)]],
      chunktypeId: ['', Validators.required],
      content: [''],
      description: [''],
      notebookName: [''],
      defaultState: [''],
    });

    if (this.mode === 'edit' && this.notebook) {
      this.chunkForm.patchValue({
        notebookName: this.notebook.name,
        defaultState: this.#appState ? this.#appState.notebook()?.defaultState : {},
      });
      this.chunkForm.get('name')?.disable();
      this.chunkForm.get('chunktypeId')?.disable();
      this.chunkForm.get('description')?.disable();
    }
  }

  ngOnInit() {
    if (this.mode === 'create') {
      this.chunkTypeService.chunkTypes$.subscribe((types: any) => {
        this.chunkTypeList = types;
      });

      this.chunkForm.get('chunktypeId')?.valueChanges.subscribe((value) => {
        this.selectedChunkType = this.chunkTypeList.find(
          (type) => type.chunktypeId === value
        );
      });
    }
  }

  ngOnDestroy() {
    // intentionally empty
  }

  uniqueNameValidator(control: AbstractControl): ValidationErrors | null {
    const existingChunk = this.chunkService.chunkList.find(
      (chunk) => chunk.name === control.value
    );
    return existingChunk ? { nonUniqueName: true } : null;
  }

  onSave() {
    if (this.chunkForm.valid) {
      if (this.mode === 'create') {
        const newChunk: CreateChunk = {
          notebookId: this.#appState.notebookId(),
          name: this.chunkForm.value.name,
          chunktypeId: this.chunkForm.value.chunktypeId,
          content: this.chunkForm.value.content,
          description: this.chunkForm.value.description,
          notebookName: this.chunkForm.value.notebookName,
        };
        this.dialogRef.close(newChunk);
      } else if (this.mode === 'edit') {
        const updatedNotebook: NotebookUpdate = {
          name: this.chunkForm.value.notebookName,
          defaultState: this.chunkForm.value.defaultState,
        };
        this.dialogRef.close(updatedNotebook);
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  updateContent(description: string) {
    this.chunkForm.get('description')?.setValue(description);
  }

  setFormTouched() {
    this.chunkForm.markAsTouched();
  }
}
