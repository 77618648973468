import { Injectable, inject } from '@angular/core';
import {
  NotebookFile,
} from '../../interfaces/file.interface';
import { GeneralHelpers } from '../../helpers/general.helper';
import { CacheService } from '../../services/cache/cache.service';
import { SnapshotService } from '../../services/snapshot/snapshot.service';
import { NotebookDefaultFilesService } from '../../services/notebook-default-file/notebook-default-file.service';
import { firstValueFrom } from 'rxjs';
import { AppStateService } from '../../services/app-state/app-state.service';

@Injectable({
  providedIn: 'root',
})
export class NotebookFilesService {
  #appState = inject(AppStateService);

  constructor(
    private cacheService: CacheService,
    private snapshotService: SnapshotService,
    private notebookDefaultFilesService: NotebookDefaultFilesService
  ) {}

  public async getNotebookFiles(): Promise<NotebookFile[]> {
    const snapshotId = this.snapshotService.getSnapshotIdFromUrl();
    let files: NotebookFile[] = [];

    if (snapshotId) {
      // If we're viewing a snapshot, only get snapshot files
      files = (await this.getNotebookFilesFromSnapshot(snapshotId)) || [];
    } else {
      // Otherwise get both cache and default files
      const [cacheFiles, defaultFiles] = await Promise.all([
        this.getNotebookFilesFromCache(),
        this.getDefaultFiles(),
      ]);

      files = [...(cacheFiles || []), ...(defaultFiles || [])];
    }

    return files.sort((a, b) => a.name.localeCompare(b.name));
  }

  private async getNotebookFilesFromSnapshot(
    snapshotId: number | null = this.snapshotService.getSnapshotIdFromUrl()
  ): Promise<NotebookFile[] | undefined> {
    if (!snapshotId || snapshotId === 0) {
      return;
    }

    try {
      const data = await firstValueFrom(
        this.snapshotService.getSnapshot(snapshotId)
      );
      const files = this.processFiles(data ?? []);
      // Mark files as snapshot files
      return files.map((file: any) => ({
        ...file,
        snapshotId: snapshotId.toString(),
      }));
    } catch (error) {
      console.log('Error getNotebookFilesFromSnapshot:', error);
      return [];
    }
  }

  private async getNotebookFilesFromCache(): Promise<
    NotebookFile[] | undefined
  > {
    try {
      const data = await firstValueFrom(this.cacheService.getCacheContent());
      return this.processFiles(data ?? []);
    } catch (error) {
      if (!(error instanceof Error && error.name === 'EmptyError')) {
        console.log('Error getNotebookFilesFromCache:', error);
      }
      return [];
    }
  }

  private async getDefaultFiles(): Promise<NotebookFile[]> {
    try {
      const projectId = this.#appState.projectId();
      const notebookId = this.#appState.notebookId();

      const defaultFiles = await firstValueFrom(
        this.notebookDefaultFilesService.getDefaultFiles(projectId, notebookId)
      );

      // Convert NotebookDefaultFile to NotebookFile format
      return defaultFiles.map((df) => ({
        fileId: df.fileId,
        cacheId: 0, // Use 0 to indicate it's a default file
        name: df.name,
        filesize: df.filesize,
        modifiedAt: new Date(df.modifiedAt || ''),
        etag: df.etag || null,
        fileType: this.setFileType({ name: df.name } as NotebookFile),
        isDefaultFile: true,
      }));
    } catch (error) {
      console.log('Error getting default files:', error);
      return [];
    }
  }

  public async getSnapshotFileById(
    snapshotId: number,
    fileId: string,
    responseType: string
  ) {
    try {
      return await firstValueFrom(
        this.snapshotService.getSnapshotFile(
          snapshotId,
          fileId,
          responseType as any
        )
      );
    } catch (error) {
      console.log('Error getSnapshotFileById:', error);
      throw error;
    }
  }

  public async getFileContent(
    fileId: string,
    isDefaultFile: boolean,
    responseType: 'blob' | 'json'
  ): Promise<any> {
    const projectId = this.#appState.projectId();
    const notebookId = this.#appState.notebookId();

    if (isDefaultFile) {
      return firstValueFrom(
        this.notebookDefaultFilesService.getDefaultFileContents(
          projectId,
          notebookId,
          fileId,
          responseType
        )
      );
    }

    return firstValueFrom(
      this.cacheService.getCacheContentFile(fileId, responseType)
    );
  }

  public async updateFileContent(
    fileId: string,
    content: Blob,
    isDefaultFile: boolean
  ): Promise<any> {
    const projectId = this.#appState.projectId();
    const notebookId = this.#appState.notebookId();

    if (isDefaultFile) {
      return firstValueFrom(
        this.notebookDefaultFilesService.updateDefaultFileContents(
          projectId,
          notebookId,
          fileId,
          content
        )
      );
    }

    return firstValueFrom(
      this.cacheService.updateCacheContentFile(fileId, content)
    );
  }

  public async createFile(
    formData: FormData,
    isDefaultFile: boolean
  ): Promise<any> {
    const projectId = this.#appState.projectId();
    const notebookId = this.#appState.notebookId();

    if (isDefaultFile) {
      return firstValueFrom(
        this.notebookDefaultFilesService.createDefaultFile(
          projectId,
          notebookId,
          formData
        )
      );
    }

    return firstValueFrom(this.cacheService.createCacheContent(formData));
  }

  public async deleteFile(
    fileId: string,
    isDefaultFile: boolean
  ): Promise<any> {
    const projectId = this.#appState.projectId();
    const notebookId = this.#appState.notebookId();

    if (isDefaultFile) {
      return firstValueFrom(
        this.notebookDefaultFilesService.deleteDefaultFile(
          projectId,
          notebookId,
          fileId
        )
      );
    }

    return firstValueFrom(this.cacheService.deleteCacheContentFile(fileId));
  }

  private processFiles(files: NotebookFile[] | any) {
    if (Array.isArray(files) === false && files.files !== undefined) {
      files = files.files;
    }
    return files.map((file: NotebookFile) => {
      const fileType = this.setFileType(file);
      return {
        ...file,
        fileType,
      };
    });
  }

  private setFileType(file: NotebookFile) {
    const fileExtension = GeneralHelpers.fileExtensionFromString(file.name);
    const fileType = GeneralHelpers.getFileType(fileExtension);
    return fileType;
  }

  public isDefaultFile(file: NotebookFile): boolean {
    return 'isDefaultFile' in file && file.isDefaultFile === true;
  }
}
