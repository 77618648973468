import { Injectable } from '@angular/core';
import { ChunkContext } from '../../interfaces/chunk/chunk-context.interface';
import { Chunk } from '../../interfaces/chunk/chunk.interface';
import { ToastrService } from 'ngx-toastr';
import { PythonModuleManager } from './python-module-manager.service';

@Injectable({
  providedIn: 'root',
})
export class PythonModulesService {
  private availableChunks: Chunk[] = [];

  constructor(
    private moduleManager: PythonModuleManager,
    private toastr: ToastrService
  ) { }

  /**
   * Initializes the service with Python runtime
   */
  // TODO: think about current implementation
  async initialize(runtime: any, jsExternalModulesNamespaces: any) {
    await this.moduleManager.initialize(runtime, jsExternalModulesNamespaces);
  }

  /**
   * Registers a chunk as a Python module
   */
  async registerChunkAsModule(
    chunk: Chunk,
    code: string = chunk.content,
    context?: ChunkContext
  ): Promise<boolean> {
    try {
      console.log('registerChunkAsModule: ', chunk.name);
      return await this.moduleManager.registerModule(
        chunk.name,
        code,
        context
      );
    } catch (error) {
      console.error(`Error registering chunk ${chunk.name} as module:`, error);
      throw error;
    }
  }

  /**
   * Imports necessary modules for a chunk based on its content
   */
  async importModulesForChunk(content: string, context: ChunkContext): Promise<void> {
    try {
      const { dependencies } = await this.moduleManager.analyzeDependencies(content);

      if (dependencies.length === 0) {
        return;
      }

      // Validate all dependencies before starting imports
      await this.moduleManager.validateModuleDependencies(dependencies, context);

      // Import modules sequentially to ensure proper order
      for (const moduleName of dependencies) {
        try {
          await this.moduleManager.importModule(moduleName, context);
          context.addMessage(`Successfully imported module: ${moduleName}`, 'info');
        } catch (error) {
          const errorMessage = `Error importing module ${moduleName}: ${error}`;
          console.error(errorMessage);
          context.addMessage(errorMessage, 'danger');
          throw error;
        }
      }
    } catch (error) {
      console.error('Error processing module dependencies:', error);
      throw error;
    }
  }

  /**
   * Updates a module when its chunk content changes
   */
  async updateChunkModule(
    chunk: Chunk,
    context: ChunkContext
  ): Promise<boolean> {
    try {
      return await this.moduleManager.updateModule(
        chunk.name,
        chunk.content,
        context
      );
    } catch (error) {
      const errorMessage = `Error updating module for chunk ${chunk.name}: ${error}`;
      console.error(errorMessage);
      if (context) {
        context.addMessage(errorMessage, 'danger');
      }
      throw error;
    }
  }

  /**
   * Updates available chunks when chunks change
   */
  updateAvailableChunks(chunks: Chunk[], context: ChunkContext): void {
    this.availableChunks = chunks.filter(chunk => chunk.chunktypeId === 3);

    this.availableChunks.forEach(chunk => {
      this.registerChunkAsModule(chunk, chunk.content).catch(error => {
        console.error(`Error registering chunk ${chunk.name}:`, error);
      });
    });
  }

  /**
   * Gets module information
   */
  getModuleInfo(moduleName: string) {
    return this.moduleManager.getModuleInfo(moduleName);
  }

  /**
   * Lists all registered chunk modules
   */
  listChunkModules(): Array<{ id: string, name: string, exports: string[] }> {
    return this.moduleManager.listModules();
  }

  /**
   * Checks if a module exists
   */
  hasModule(moduleName: string): boolean {
    return this.moduleManager.getModuleInfo(moduleName) !== undefined;
  }
}
