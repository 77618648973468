import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog } from '../../interfaces/create-dialog.interface';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-manage-team-dialog',
  templateUrl: './manage-team-dialog.component.html',
  styleUrls: ['./manage-team-dialog.component.scss']
})
export class ManageTeamDialogComponent implements OnInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Dialog) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
