import {
  Component,
  Inject,
  inject,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ArrowAliasService } from '../../../services/arrow-alias/arrow-alias.service';
import {
  ArrowAliasV2,
  CreateArrowAlias,
  DEFAULT_ARROW_URL,
  SaveArrowAlias,
} from '../../../interfaces/arrow-alias.interface';
import { NonNullableFormBuilder } from '@angular/forms';
import { Dialog } from '../../../interfaces/create-dialog.interface';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import {
  GuiGridComponent,
  GuiColumn,
  GuiDataType,
  GuiRowClass,
  GuiSelectedRow,
  GuiSorting,
} from '@generic-ui/ngx-grid';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'arrow-alias-manager',
  templateUrl: './alias-manager.component.html',
  styleUrl: './alias-manager.component.scss',
})
export class AliasManagerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('aliasGrid', { static: true })
  aliasGrid!: GuiGridComponent;
  projectId!: string | any;
  aliasList!: ArrowAliasV2[];
  selectedAlias: ArrowAliasV2 | undefined;
  addingAlias = false;
  errorMessage: string | undefined;
  #appState = inject(AppStateService);

  constructor(
    public dialogRef: MatDialogRef<AliasManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Dialog,
    private formBuilder: NonNullableFormBuilder,
    private arrowAliasService: ArrowAliasService,
    private cdRef: ChangeDetectorRef
  ) {
    this.projectId = this.#appState.projectId();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.arrowAliasService.aliasV2List$.subscribe({
        next: (data) => {
          this.aliasList = data;
          this.aliasGrid.api.setSource(this.aliasList);
          this.cdRef.detectChanges(); // Trigger change detection
        },
        error: (e) => this.handleError(e),
      });

      this.aliasForm.disable();
      this.aliasForm.reset();
    });
  }

  ngOnDestroy(): void {
    // This is intentional
  }

  handleError(error: any, message?: string) {
    console.error(error);
    if (message === undefined) {
      if (error instanceof Error) {
        message = error.message;
      } else {
        message = error.toString();
      }
    }
    this.errorMessage = message;
  }

  resetError() {
    this.errorMessage = undefined;
  }

  aliasForm = this.formBuilder.group({
    aliasId: 1000,
    name: [''],
    cloudConfig: this.formBuilder.group({
      bucket: [''],
      endpoint: [''],
      access_key: [''],
      region: ['nyc3'],
    }),
    cloudConfigSecret: [''],
  });

  onAliasSelected(selection: Array<GuiSelectedRow>): void {
    if (selection.length === 0) {
      this.selectedAlias = undefined;
      this.aliasForm.reset();
      this.aliasForm.disable();
    } else {
      this.selectedAlias = selection[0].source as ArrowAliasV2;
      this.loadAlias(this.selectedAlias);
      this.aliasForm.enable();
    }
    this.cdRef.detectChanges(); // Trigger change detection
  }

  loadAlias(alias: ArrowAliasV2): void {
    this.aliasForm.patchValue(alias);
    this.cdRef.detectChanges(); // Trigger change detection
  }

  aliasListSorting: GuiSorting = {
    enabled: true,
  };

  aliasListColumns: Array<GuiColumn> = [
    {
      header: 'name',
      field: 'name',
      type: GuiDataType.STRING,
      width: 220,
    },
    {
      header: 'aliasId',
      field: 'aliasId',
      type: GuiDataType.STRING,
      width: 80,
    },
  ];

  aliasListRowClass: GuiRowClass = {
    class: 'alias-list-row',
  };

  close(): void {
    this.dialogRef.close();
  }

  get disableButtons() {
    if (this.addingAlias) return false;
    return this.selectedAlias !== undefined;
  }

  doAddAlias() {
    this.resetError();
    this.addingAlias = true;
    let alias = {
      aliasId: 0,
      versionId: 0,
      projectId: 0,
      name: 'alias ' + (this.aliasList.length + 1),
      url: DEFAULT_ARROW_URL,
      cloudConfig: {
        endpoint: 'https://nyc3.digitaloceanspaces.com',
        region: 'nyc3',
        bucket: '',
        access_key: '',
      },
      cloudConfigSecret: '',
    };
    this.aliasGrid.api.unselectAll();
    this.selectedAlias = alias;
    this.loadAlias(alias);
    this.aliasForm.enable();
    this.cdRef.detectChanges(); // Trigger change detection
  }

  doDuplicateAlias() {
    this.resetError();
    if (this.selectedAlias) {
      this.arrowAliasService
        .duplicate(this.projectId, this.selectedAlias.aliasId)
        .subscribe({
          next: (data) => {
            // don't need to do anything, subscription to aliasV2List$ will update the list
            this.onAliasSelected([]);
          },
          error: (e) => this.handleError(e),
        });
    }
  }

  doDeleteAlias() {
    this.resetError();
    const alias = this.selectedAlias;
    if (alias === undefined) return;
    this.arrowAliasService.delete(this.projectId, alias.aliasId).subscribe({
      next: (data) => {
        // subscription to aliasV2List$ will update the list
        this.onAliasSelected([]);
      },
      error: (e) => this.handleError(e),
    });
  }

  doResetForm() {
    this.resetError();
    if (this.selectedAlias) {
      const alias = { ...this.selectedAlias, cloudConfigSecret: '' };
      this.aliasForm.setValue(alias);
    } else {
      this.aliasForm.reset();
    }
    this.cdRef.detectChanges(); // Trigger change detection
  }
  doSaveAlias() {
    const curAlias = this.aliasForm.value as ArrowAliasV2;
    if (curAlias.cloudConfig!.bucket === '') {
      this.handleError('Bucket name is required');
      return;
    }
    if (curAlias.cloudConfig!.access_key === '') {
      this.handleError('Access key is required');
      return;
    }
    if (curAlias.cloudConfig!.endpoint.length < 5) {
      this.handleError('Valid endpoint is required');
      return;
    }
    if (this.addingAlias) {
      let alias: CreateArrowAlias = {
        name: curAlias.name,
        url: DEFAULT_ARROW_URL,
        token: '',
        cloudBased: true,
        cloudConfig: JSON.stringify(curAlias.cloudConfig),
        cloudConfigSecret: curAlias.cloudConfigSecret || '',
      };
      if (alias.cloudConfigSecret.length === 0) {
        this.handleError('Secret is required');
        return;
      }
      this.arrowAliasService.create(alias, this.projectId).subscribe({
        next: (data) => {
          this.addingAlias = false;
          this.onAliasSelected([]);
        },
        error: (e) => this.handleError(e),
      });
      this.resetError();
    } else {
      let alias: SaveArrowAlias = {};
      if (curAlias.name !== this.selectedAlias!.name) {
        alias.name = curAlias.name;
      }
      if (
        JSON.stringify(curAlias.cloudConfig) !==
        JSON.stringify(this.selectedAlias!.cloudConfig)
      ) {
        alias.cloudConfig = JSON.stringify(curAlias.cloudConfig);
      }
      if (curAlias.cloudConfigSecret && curAlias.cloudConfigSecret.length > 0) {
        alias.cloudConfigSecret = curAlias.cloudConfigSecret;
      }
      this.arrowAliasService
        .update(this.projectId, curAlias.aliasId, alias)
        .subscribe({
          next: (data) => {
            this.onAliasSelected([]);
          },
          error: (e) => this.handleError(e),
        });
    }
    this.cdRef.detectChanges(); // Trigger change detection
  }
}
